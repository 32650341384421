import { Component } from '@angular/core';
import { KeycloakService, AuthService } from '@plantandfood/kup.auth';
import { NavLinks, TypeHelper } from '@plantandfood/kup.core';
import { appNavLinks } from './app-nav-links';
import { KupEnvService } from '@plantandfood/kup.env';
import { HttpClient } from '@angular/common/http';

declare const window: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Gate Meeting Reporting Application';
  links: NavLinks = appNavLinks;
  readonly showTestBanner: boolean;
  readonly envName: string;
  accountName: string;
  appVersion: string;
  allApps: any;
  baseUrl: string;
  urlForApps: string;

  constructor(
    private keycloakService: KeycloakService,
    private authService: AuthService,
    private envService: KupEnvService,
    private http: HttpClient
  ) {
    this.showTestBanner =
      TypeHelper.sanitizeString(
        this.envService.get<string>('showTestBanner')
      ).toLowerCase() === 'true';
    this.appVersion = window.env.version;
    this.loadUserProfile();
  }

  ngOnInit() {
    this.baseUrl = this.envService
      .get<string>('managementApiHost')
      .toString()
      .substring(8);
    if (this.baseUrl.slice(-1) == '/') {
      this.urlForApps =
        'https://' + this.baseUrl + 'aapi/applications?sort=name';
    } else {
      this.urlForApps =
        'https://' + this.baseUrl + '/aapi/applications?sort=name';
    }

    this.http.get<any>(this.urlForApps).subscribe(app => {
      this.allApps = Object.keys(app).map(key => app[key])[0].applications;
      this.allApps.forEach((element, index) => {
        if (element.description === this.title) {
          this.allApps.splice(index, 1);
        }
      });
    });
  }

  onLogout() {
    this.keycloakService.logout();
  }

  private loadUserProfile() {
    this.authService.loadProfileData().subscribe(res => {
      this.accountName = `${res.firstName} ${res.lastName}`;
    });
  }

  navToApp(app) {
    window.open(
      app.url.toString().replace(/{base-url}/gi, this.baseUrl),
      '_blank'
    );
  }
}
