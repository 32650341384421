import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { NewRepeatTypeComponent, ButtonTypeComponent } from '@plantandfood/kup.core';


@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        FormlyModule.forRoot({
            validationMessages: [
                { name: 'required', message: 'This field is required' },
            ],
            types: [
                { name: 'repeat', component: NewRepeatTypeComponent },
                {
                    name: 'button', component: ButtonTypeComponent,
                    defaultOptions: {
                        templateOptions: {
                            type: 'button'
                        }
                    }
                }
            ],

        }),
        FormlyMaterialModule,
        CommonModule,
        FormlyMatDatepickerModule,
        FlexLayoutModule
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        FormlyModule,
        FormlyMaterialModule,
        CommonModule,
        FormlyMatDatepickerModule,
        FlexLayoutModule
    ],
    declarations: []
})
export class AppFormlyModule { }
