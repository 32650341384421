import { NavLinks } from '@plantandfood/kup.core';

export const appNavLinks: NavLinks = [
  {
    path: '/meetings',
    label: 'Meetings',
    svgLigature: 'more_vert',
    id: 'sidenav_link_meetings'
  },

];
