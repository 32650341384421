import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FilterPanelModule, NavbarModule, SidebarModule } from 'kup.common.ui';
import { KupCoreModule } from '@plantandfood/kup.core';
import { AppFormlyModule } from './formly.module';

import { SimpleTableComponent } from './table/simple-table.component';
import { TableComponent } from './table/table.component';


@NgModule({
  imports: [
    SweetAlert2Module.forRoot(),
    KupCoreModule,
    RouterModule,
    AppFormlyModule,
    FlexLayoutModule,
    FontAwesomeModule,
    FilterPanelModule,
    SidebarModule,
    NavbarModule,
  ],
  exports: [
    SweetAlert2Module,
    KupCoreModule,
    RouterModule,
    FlexLayoutModule,
    AppFormlyModule,
    FontAwesomeModule,
    FilterPanelModule,
    SidebarModule,
    NavbarModule,
    TableComponent,
    SimpleTableComponent,
  ],
  declarations: [
    TableComponent,
    SimpleTableComponent
  ],
  entryComponents: [

  ],
  providers: []
})
export class SharedModule { }
