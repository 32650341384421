import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared/shared.module';

import { KupEnvModule, KupEnvService } from '@plantandfood/kup.env';
import { KupAuthModule } from '@plantandfood/kup.auth';
import { KupCoreModule } from '@plantandfood/kup.core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppLocaleProvider } from './core/i18n/locale.provider';
import { KupIconConfig } from '@plantandfood/kup.core/lib/constants/icons.constants';

declare const window: any;

export function envFactory() {
  return window.env;
}

export function authConfigFactory(envService: KupEnvService) {
  return {
    keycloakConfig: envService.get<any>('keycloakConfigOptions'),
    keycloakInitOptions: envService.get<any>('keycloakInitOptions')
  };
}

export function coreConfigFactory(envService: KupEnvService) {
  return {
    managementApi:
      envService.get<string>('managementApiHost') +
      envService.get<string>('managementApiPath'),
    printingApi:
      envService.get<string>('managementApiHost') +
      envService.get<string>('printingApiPath'),
    hApi:
      envService.get<string>('managementApiHost') +
      envService.get<string>('hApiPath'),
    textResourceSet: {},
    icons: navKupIcons
  };
}
export const navKupIcons: KupIconConfig[] = [
  { name: 'keyboard_arrow_right', file: 'keyboard_arrow_right.svg' },
  { name: 'info', file: 'info_black.svg' },
  { name: 'account_circle', file: 'account_circle.svg' },
  { name: 'exit_to_app', file: 'exit_to_app.svg' },
  { name: 'more_vert', file: 'more_vert.svg' },
  { name: 'menu', file: 'menu.svg' }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    KupEnvModule.forRoot({
      useFactory: envFactory
    }),
    KupCoreModule.forRoot({
      useFactory: coreConfigFactory,
      deps: [KupEnvService]
    }),
    KupAuthModule.forRoot({
      useFactory: authConfigFactory,
      deps: [KupEnvService]
    }),

    // Eager Loaded Features
    DashboardModule

    // NOTE: ErrorPagesModule must be last
  ],
  providers: [
    AppLocaleProvider,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
